<template>
  <div class="w-full max-w-5xl mx-auto py-24">
    <div class="flex space-x-8 w-full items-center">
      <img src="@/assets/imgs/jody-signin.png" alt="" />
      <div
        class="rounded bg-background-2 px-12 py-6 flex-1 divide-y divide-white"
      >
        <div class="text-center text-2xl font-bold pb-4">訂單內容</div>
        <div class="flex justify-end text-gray py-2 text-sm">
          <div>
            <p>訂單日期：{{ row.date }}</p>
            <p v-if="orderId">訂單編號：{{ orderId }}</p>
            <p v-if="code">兌換碼: {{ code }}</p>
          </div>
        </div>
        <div v-if="status === '009'" class="py-2">
          <table class="info-table text-lg">
            <tr class="text-danger">
              <td class="whitespace-nowrap">訂單狀態：</td>
              <td>{{ msg }}</td>
            </tr>
            <tr>
              <td>方案名稱：</td>
              <td>{{ row.title }}</td>
            </tr>
            <tr>
              <td>實付金額：</td>
              <td>{{ row.payPrice }}</td>
            </tr>
            <tr>
              <td>電子郵件：</td>
              <td v-html="row.email"></td>
            </tr>
          </table>
          <div class="flex space-x-4 mt-8">
            <el-button
              type="info"
              size="default"
              @click="router.push('/account/billing')"
              >購買紀錄</el-button
            >
            <el-button
              type="info"
              size="default"
              @click="router.push('/account/history')"
              >會員方案紀錄</el-button
            >
          </div>
        </div>
        <div v-else class="text-danger py-2">訂單狀態： {{ msg }}</div>
      </div>
    </div>

    <!--status.value === '009' 表示成功 -->
    <!-- <div v-if="status.value === '009'">
      成功
      <el-button>點擊回到訂單列表頁</el-button>
    </div>
    <div v-else>{{ msg }}</div> -->
  </div>
</template>

<script setup>
import { ref, reactive, onUnmounted, onBeforeUnmount, inject } from 'vue';
import useTimeoutPoll from '@/utils/useTimeoutPoll';

import Payment from '@/api/Payment';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

const route = useRoute();
const router = useRouter();

const orderId = route.query.orderId;
const code = route.query.code;
const row = reactive({});
const auth = inject('auth');
let status = ref('001');
const msg = ref(null);
const messages = {
  '001': '確認中',
  '002': '訂單失敗',
  '003': '訂單建立失敗',
  '009': '已完成',
};

const count = ref(0);
const stop = ref(false);
const check = async () => {
  const res = await Payment.nonAuthCheck({ orderId, code });

  if (res.code == 1) {
    Object.assign(row, res.data);
    // 009是成功
    status.value = res.errorCode ? res.errorCode : '009';
    msg.value = messages[status.value];

    if (
      status.value === '002' ||
      status.value === '003' ||
      status.value === '009'
    ) {
      pause();
      await auth.status();
    } else {
      if (stop.value) return;
      resume();
    }
  } else {
    if (res.status >= 400) {
      let msg = '錯誤';
      if (res.status == 400) {
        msg = '訂單號碼或錯誤';
      }
      ElMessage(msg);
      pause();
    } else {
      if (count.value <= 2) {
        if (stop.value) return;
        resume();
        count.value++;
      }
    }
  }
};
const { pause, resume } = useTimeoutPoll(check, 1000);
onUnmounted(() => {
  stop.value = true;
  pause();
});

onBeforeUnmount(() => {
  stop.value = true;
  pause();
});

if (orderId || code) {
  resume();
} else {
  ElMessage('沒有訂單號碼');
}
</script>

<style lang="sass" scoped>
.info-table
  tr
    td
      @apply align-text-top py-1
      &:first-child
        @apply w-32 mr-6
</style>
